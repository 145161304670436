<template>
  <div>
    <files-manager v-model="files" :signDeleted="true" label="Adaugă documente">
    </files-manager>
    <div class="">
      <b>Documente încarcate</b>

      <div style="position: relative;">
        <transition-group name="popup">
          <h4
            key="0"
            v-if=" !localProcedureDocument || !localProcedureDocument.documents || localProcedureDocument.documents.length == 0"
            class="prettyH4"
            style="color: #888;position: absolute;top: 0;left: 0;right: 0;"
          >
            <i class="fas fa-times"></i>
            Nu sunt documente
          </h4>
          <div
            key="1"
            v-else
            class="TBParent referenceStatusesBeautify"
            style="max-height: 60rem !important;"
          >
            <table>
              <thead>
                <th style="width: 4rem;">Nr.</th>
                <th>Denumire</th>
                <th style="display: flex; justify-content: flex-end;">
                  Acțiuni
                </th>
              </thead>
              <tbody>
                <tr
                  v-for="(doc, index) in localProcedureDocument.documents"
                  :key="index"
                >
                  <td>{{ index + 1 }}.</td>
                  <td>{{ doc }}</td>
                  <td
                    style="display: flex; justify-content: flex-end; cursor: pointer;"
                  >
                    <div
                      style="display: flex;align-items: center;"
                      @click="viewPdf(doc)"
                    >
                      <i
                        v-tooltip="{ content: 'Deschide fișier' }"
                        style="color: #337ab7;"
                        class="fas fa-external-link-alt"
                      ></i>
                    </div>
                    <div
                      v-if="procedureDocument.status == 'draft'"
                      style="margin: 0px 10px;"
                    >
                      |
                    </div>
                    <div
                      v-if="procedureDocument.status == 'draft'"
                      style="display: flex;align-items: center; cursor: pointer;"
                      @click="deleteDoc(doc)"
                    >
                      <i
                        v-tooltip="{ content: 'Șterge fișier' }"
                        style="color: #ec4a4a;"
                        class="fas fa-trash"
                      ></i>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </transition-group>
      </div>
    </div>
  </div>
</template>

<script>
import FilesAgent from "@/components/FilesAgent";
import { mapMutations } from "vuex";
import { PROCEDURE_DOCUMENTS } from "@/api.js";
import DialogModal from "@/components/DialogModal";

export default {
  components: {
    "files-manager": FilesAgent,
  },
  props: {
    procedureDocument: {
      type: Object,
      default: () => null
    },
  },
  data() {
    return {
      files: [],
      localProcedureDocument: this.procedureDocument,
    };
  },
  watch: {
    files: {
      handler(val) {
        this.$emit("input", val);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapMutations(["setReferenceIdForPreview", "addWindow"]),
    viewPdf(name) {
      this.addWindow(
        Object.assign({
          fileList: [name],
          itemId: this.localProcedureDocument.id,
          canDelete: false,
          modal: 13,
          select: 0,
          searchModuleApi: PROCEDURE_DOCUMENTS.getDocument,
        })
      );
    },
    deleteDoc(name) {
      this.$modal.show(
        DialogModal,
        {
          target: "DeleteAcquisitionDocument",
          title: "Șterge Document",
          inputs: [],
          closeBtnText: "Anulează",
          button: {
            type: 3,
            value: "Șterge",
            handler: () => {
              const error = (msg) => {
                this.$toastr.e(msg || "Documentul nu a fost șters.");
                this.setLoad();
              };

              this.setLoad(true);
              PROCEDURE_DOCUMENTS.deleteDocument(this.localProcedureDocument.id, {
                fileName: name,
              })
                .then((res) => {
                  if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
                    this.$toastr.s("Documentul a fost șters.");
                    this.localProcedureDocument.documents = this.localProcedureDocument.documents.filter(
                      (el) => el != name
                    );
                    this.setLoad();
                  } else {
                    error(res.response);
                  }
                })
                .catch(error);
            },
          },
        },
        {
          name: "DeleteAcquisitionDocument",
          adaptive: true,
          width: "550",
          height: "300",
        }
      );
    },
  },
  created() {
  console.log(this.localProcedureDocument);
    this.$emit("input", this.files);
  },
};
</script>

<style></style>
