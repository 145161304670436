export default {
  data() {
    return {
      PERMISIONS: {
        list: false,
      },
    };
  },
  created() {
    const baseRule = "assignments";

    this.initPermision(`${baseRule}.get`, "list");
  },
};
